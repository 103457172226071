import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Media, MediaAspectRatio, MediaLoading, MediaObjectPosition } from '../../types/media';

// Model -------------------------------------------------------------------------------------------
export interface ImageOptimizationSettings {
	divisor?: number;
	quality?: number;
	makePersistent?: boolean;
	usePlaceholder?: boolean;
	maxFileSize?: number;
}

export interface MediaModel {
	media: Media;
	aspectRatio?: MediaAspectRatio;
	loading?: MediaLoading;
	objectPosition?: MediaObjectPosition;
	width?: number;
	fullWidthPicture?: boolean;
	fullWidthImg?: boolean;
	fullHeight?: boolean;
	contain?: boolean;
	preload?: boolean;
	isLcp?: boolean;
	previewImageOptimization?: boolean;
	previewImageSettings?: ImageOptimizationSettings;
}

// Props -------------------------------------------------------------------------------------------

export const MediaProps: Props<MediaModel> = {
	media: {
		type: Object as PropType<MediaModel['media']>,
		required: true,
	},
	aspectRatio: {
		type: Object as PropType<MediaModel['aspectRatio']>,
	},
	loading: {
		type: String as PropType<MediaModel['loading']>,
		default: MediaLoading.LAZY,
	},
	objectPosition: {
		type: String as PropType<MediaModel['objectPosition']>,
		default: MediaObjectPosition.CENTER,
	},
	width: {
		type: Number as PropType<MediaModel['width']>,
	},
	fullWidthPicture: {
		type: Boolean as PropType<MediaModel['fullWidthPicture']>,
		default: false,
	},
	fullWidthImg: {
		type: Boolean as PropType<MediaModel['fullWidthImg']>,
		default: false,
	},
	fullHeight: {
		type: Boolean as PropType<MediaModel['fullWidthPicture']>,
		default: false,
	},
	contain: {
		type: Boolean as PropType<MediaModel['contain']>,
		default: false,
	},
	preload: {
		type: Boolean as PropType<MediaModel['preload']>,
		default: false,
	},
	isLcp: {
		type: Boolean as PropType<MediaModel['isLcp']>,
		default: false,
	},
	previewImageOptimization: {
		type: Boolean as PropType<MediaModel['previewImageOptimization']>,
		default: false,
	},
	previewImageSettings: {
		type: Object as PropType<MediaModel['previewImageSettings']>,
	},
};
