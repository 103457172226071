var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.media)?_c('LazyHydrate',{key:_vm.isPlaceholderActive ? 'placeholder' : 'image',attrs:{"never":!_vm.isHydrationNeeded,"when-visible":_vm.isHydrationNeeded}},[_c('picture',_vm._g({class:[
			'media',
			'tw-flex',
			_vm.aspectRatioClassnames,
			{ 'tw-w-full': _vm.fullWidthPicture },
			{ 'tw-h-full': _vm.fullHeight },
			{ 'media--preloaded': _vm.preload },
			{ 'media--is-lcp': _vm.isLcp } ],attrs:{"title":_vm.conditionalMediaObject.title,"data-name":"media"}},_vm.$listeners),[_vm._l((_vm.isLoadingLazy ? _vm.media.sources : _vm.conditionalMediaObject.sources),function(source){return _c('source',{key:source.media,attrs:{"media":source.media,"srcset":_vm.isLoadingLazy ? _vm.defaultPlaceholder : source.srcset,"data-srcset":_vm.isLoadingLazy ? source.srcset : undefined}})}),_c('img',{class:[
				{ lazyload: _vm.isLoadingLazy },
				{ 'tw-w-full': _vm.fullWidthPicture || _vm.fullWidthImg },
				{ 'tw-h-full': _vm.fullHeight },
				_vm.objectFitClassname,
				_vm.objectPosition ],attrs:{"alt":_vm.conditionalMediaObject.alt,"data-src":_vm.isLoadingLazy ? _vm.media.src : undefined,"fetchpriority":_vm.preload ? 'high' : undefined,"height":_vm.imgPropHeight,"loading":!_vm.previewImageSettingsData.usePlaceholder ? _vm.loading : undefined,"src":_vm.isLoadingLazy ? _vm.defaultPlaceholder : _vm.conditionalMediaObject.src,"width":_vm.imgPropWidth}})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }