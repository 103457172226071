export interface Breakpoint {
	IS_DESKTOP: boolean;
	IS_TABLET: boolean;
	IS_MOBILE: boolean;
}

export enum Breakpoints {
	MOBILE = 0,
	TABLET = 768,
	DESKTOP = 1280,
}
