import { render, staticRenderFns } from "./Media.vue?vue&type=template&id=0da1b4a6&"
import script from "./Media.ts?vue&type=script&lang=ts&"
export * from "./Media.ts?vue&type=script&lang=ts&"
import style0 from "./Media.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports